<template>
    <div>
        <b-row>
            <b-col cols="12">
                <b-form-group :label="$t('name')">
                    <b-input-group >
                        <div class="label-as-input">{{ isNotNullValue(form.course_name) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="12">
                <b-form-group :label="$t('start_date')">
                    <b-input-group >
                        <div class="label-as-input">{{ isNotNullValue(form.start_date) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="12">
                <b-form-group :label="$t('end_date')">
                    <b-input-group >
                        <div class="label-as-input">{{ isNotNullValue(form.end_date) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="12">
                <b-form-group :label="$t('evaluation')">
                    <b-input-group >
                        <div class="label-as-input">{{ isNotNullValue(form.evaluation_name) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    //Service
    import SessionService from "@/services/SessionService";

    export default {
        props: {
            formId: {
                type: Number,
            }
        },
        data() {
            return {
                form: {
                    course_id: null,
                    start_date: null,
                    end_date: null,
                    evaluation_id: null,
                }
            }
        },
        async created() {
            await this.get(this.formId)
        },
        methods: {
            get(id) {
                SessionService.get(id)
                                .then((response) => {
                                    this.form = response.data.data;
                                })
                                .catch((error) => {
                                    this.showErrors(error)
                                })
            },

        }
    }
</script>
